export default {
  total: (state) => state.list.length,
  latest: (state) => state.latest,
  list: ({ list, filters }) => list.filter(({
    searchable,
    resolvedAt,
    createdBy,
    company,
    document,
  }) => (
    (!filters.search || searchable.some((value) => value.includes(filters.search)))
    && (!filters.status || !!resolvedAt === (filters.status === 'resolved'))
    && (!filters.createdBy || createdBy.id === parseInt(filters.createdBy, 10))
    && (!filters.company || company.id === parseInt(filters.company, 10))
    && (!filters.jurisdiction || company.jurisdiction === filters.jurisdiction)
    && (!filters.documentType || document.type === filters.documentType)
  )),
  options: (state) => state.options,
};
