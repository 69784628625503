import { getStatus, getCompletion } from '@/utils/completion';
import dateTime from '@/utils/dateTime';

export default {
  setList: (state, funds) => {
    state.list = funds.map((fund) => {
      const { closedDocuments } = fund;

      const totalDocuments = fund.openDocuments + closedDocuments;
      const percentage = parseFloat(closedDocuments / totalDocuments) * 100;

      const status = getStatus(percentage);
      const completion = getCompletion(closedDocuments, totalDocuments, percentage);

      return {
        id: fund.id,
        name: fund.name,
        status,
        completion,
        assignee: fund.assignee?.name || '',
        parent: fund.parent?.name,
        searchable: [
          fund.name,
          status.label,
          completion.label,
          completion.title,
          ...(fund.assignee?.name ? [fund.assignee.name] : []),
          ...(fund.parent?.name ? [fund.parent.name] : []),
        ].map((value) => value.toLowerCase()),
      };
    });
  },
  setOptions: (state, funds) => {
    // Finds all the unique statuses on the formatted list of funds and formats them for the options
    // of the `FormControl` component
    state.options.status = state.list.reduce((options, { status }) => {
      if (options.some((option) => option.value === status.label)) {
        return options;
      }

      return [
        ...options,
        {
          label: `${status.label[0].toUpperCase()}${status.label.slice(1)}`,
          value: status.label,
        },
      ];
    }, []);

    // Finds all the unique assignees on the list of funds and formats them for the options of the
    // `FormControl` component
    state.options.assignee = funds.reduce((options, { assignee }) => {
      if (options.some((option) => option.value === assignee?.name) || !assignee) {
        return options;
      }

      return [
        ...options,
        {
          label: assignee.name,
          value: assignee.name,
        },
      ];
    }, []);
  },
  setFilters: (state, filters) => {
    state.filters = { ...filters };
  },
  setCurrent: (state, fund) => {
    state.current = {
      id: fund.id,
      name: fund.name,
      assignee: fund.assignee?.id,
      parent: fund.parent?.name,
      createdAt: dateTime.format(fund.createdAt),
      updatedAt: dateTime.format(fund.updatedAt),
      updatedBy: fund.updatedBy?.name,
      companies: fund.companies?.map((company) => {
        const { closedDocuments } = company;

        const totalDocuments = company.openDocuments + closedDocuments;
        const percentage = parseFloat(closedDocuments / totalDocuments) * 100;

        return {
          id: company.id,
          name: company.name,
          status: getStatus(percentage),
          funds: company.funds.map((item) => item.name).join(', '),
          completion: getCompletion(closedDocuments, totalDocuments, percentage),
          jurisdiction: company.jurisdiction,
        };
      }),
    };
  },
};
