export default {
  list: (state) => state.list,
  getOptions: (state) => (levels) => {
    let subjects = state.list;
    const options = [subjects];

    levels.forEach((level) => {
      const subject = subjects.find(({ id }) => level === id);

      if (subject?.children) {
        subjects = subject.children;

        options.push(subjects);
      }
    });

    return options;
  },
};
