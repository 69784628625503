import {
  post,
  put,
  remove,
} from '@/utils/request';

const basePath = '/annotations';

/**
 * Creates an annotation
 *
 * @param {object} annotation - Details of the annotation to be created
 *
 * @returns {Promise} - Response promise
 */
export async function create(annotation) {
  const request = await post(basePath, annotation);
  const { promise } = await request;

  return promise;
}

/**
 * Updates an annotation
 *
 * @param {object} annotation - Details of the annotation to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function update(annotation) {
  const request = await put(`${basePath}/${annotation.id}`, annotation);
  const { promise } = await request;

  return promise;
}

/**
 * Removes an annotation
 *
 * @param {object} id - ID of the annotation to be deleted
 *
 * @returns {Promise} - Response promise
 */
export async function deleteAnnotation(id) {
  const request = await remove(`${basePath}/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Removes an voting threshold from an annotation
 *
 * @param {object} id - ID of the voting threhsold to be removed
 *
 * @returns {Promise} - Response promise
 */
export async function removeVotingThreshold(id) {
  const request = await remove(`${basePath}/votingthresholds/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Creates a comment
 *
 * @param {number} annotationId - ID of the annotation where to create the comment
 * @param {object} comment - Details of the comment to be created
 *
 * @returns {Promise} - Response promise
 */
export async function createComment(annotationId, comment) {
  const request = await post(`${basePath}/${annotationId}/comments`, comment);
  const { promise } = await request;

  return promise;
}
