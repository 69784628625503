/**
 * Returns the completion type (used on the `Badge` component) of an entity, based on the completion
 * percentage
 *
 * @param {number} percentage - Percentage of onboarding completion
 *
 * @returns {string} - Completion type
 */
export default (percentage) => {
  if (percentage < 50) {
    return 'danger';
  }

  if (percentage < 100) {
    return 'warning';
  }

  return 'success';
};
