import request from './private/request';

/**
 * Performs HTTP `GET` requests
 *
 * @param {string} url - URL where to perform the request
 * @param {object} params - Set of params to serialize on the URL
 *
 * @returns {object} - Object with the request promise and a method to cancel it
 */
export default (url, params) => (
  request(`${url}${params ? `?${new URLSearchParams(params).toString()}` : ''}`)
);
