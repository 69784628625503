<template>
  <router-view/>
  <FeedbackMessages />
</template>

<script>
import FeedbackMessages from '@/components/FeedbackMessages.vue';

import { defineComponent } from 'vue';

import store from '@/store';

export default defineComponent({
  components: {
    FeedbackMessages,
  },
  errorCaptured(error) {
    store.dispatch('feedback/showError', `There has been an unexpected error! These are the details "${error.message}"`);
  },
});
</script>
