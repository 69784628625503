import mapRoutesToPaths from './private/mapRoutesToPaths';

/**
 * Finds a route based on a full path on a list of routes
 *
 * @param {[object]} routes - List of routes to iterate (can have children)
 * @param {string} routePath - Full path of the route to be found
 *
 * @returns {object} Found route or null if not found
 */
export default (routes, routePath) => {
  const paths = mapRoutesToPaths(routes);

  if (paths[routePath]) {
    return paths[routePath];
  }

  return null;
};
