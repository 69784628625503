<template>
  <div
    class="feedback"
    :class="{
      [`feedback--${type}`]: !!type,
    }"
  >
    <div class="feedback__header">
      <div class="feedback__title">
        {{ getTitle }}
      </div>
      <button
        class="feedback__close"
        v-if="close"
        @click="close()"
      />
    </div>
    <slot v-if="$slots.default" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const defaultTitles = {
  error: 'Oh no!',
  info: 'Did you know...',
  success: 'All good!',
  warning: 'Careful!',
};

const defaultType = 'success';

export default defineComponent({
  props: {
    title: String,
    type: {
      type: String,
      validator: (value) => ['error', 'info', 'warning'].includes(value),
    },
    close: Function,
  },
  created() {
    if (!this.$slots.default) {
      throw new Error('The `Feedback` component requires a child to be passed with the message');
    }
  },
  computed: {
    getTitle() {
      return this.title || defaultTitles[this.type || defaultType];
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'utils/mixins/text' as *;
@use 'utils/mixins/z' as *;

.feedback {
  --color-background: var(--color-feedback-success);

  @extend %p-3;
  @extend %p-2;

  @include text('sm');

  background-color: var(--color-background);
  border-radius: var(--border-radius-sm);
  color: var(--color-secondary);
  position: relative;
  width: 100%;

  &--error {
    --color-background: var(--color-feedback-danger);
  }

  &--warning {
    --color-background: var(--color-feedback-warning);
  }

  &--info {
    --color-background: var(--color-secondary-light);
  }

  &__header {
    @include text('md');

    display: flex;

    &:not(:last-child) {
      @extend %pb-1;

      @include text(null, 'bold');
    }
  }

  &__title {
    @extend %pr-1;
    @extend %mr-auto;
  }

  &__close {
    height: 2.1rem;
    position: relative;
    width: 2.1rem;

    &::before,
    &::after {
      background-color: var(--color-secondary);
      content: '';
      height: 2.1rem;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 2px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
</style>

<style lang="scss">
@use 'utils/mixins/text' as *;

.feedback {
  a,
  .button.button--link {
    @include text(null, 'bold');

    color: inherit;
    text-decoration: underline;
  }
}
</style>
