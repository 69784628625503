export default {
  list: (state) => state.list,
  getItemById: (state) => (id) => (
    state.list.find((feedback) => feedback.id === id) || null
  ),
  getItemByTypeAndMessage: (state) => (type, message) => (
    state.list.find((feedback) => (
      feedback.type === type && feedback.message === message
    )) || null
  ),
  getItemIndex: (state) => (feedback) => state.list.indexOf(feedback),
};
