import api from '@/utils/api';

export default {
  fetchLatest: async ({ commit }) => {
    const comments = await api.comments.getLatest();

    if (comments) {
      commit('setLatest', comments);
    }
  },
  fetchAll: async ({ commit }) => {
    const comments = await api.comments.getAll();

    if (comments) {
      commit('setList', comments);
      commit('setOptions', comments);
    }
  },
  applyFilters: ({ commit }, filters) => {
    commit('setFilters', filters);
  },
};
