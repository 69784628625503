/**
 * Reduces recursively a list of routes (ahd child routes) to get all path permutations
 *
 * @param {[object]} routes - List of routes to iterate (can have children)
 * @param {string} pathPrepend - Path to prepend to the child routes in order to get the full path
 *
 * @returns {[object]} - Key/value pair consisting on full route path and route object
 */
const mapRoutesToPaths = (routes, pathPrepend = '') => (
  routes.reduce((paths, route) => {
    const path = (pathPrepend && `${pathPrepend}/`) + route.path;
    const childrenPaths = route.children ? mapRoutesToPaths(route.children, path) : [];

    return {
      ...paths,
      ...childrenPaths,
      ...{
        [path]: route,
      },
    };
  }, {})
);

export default mapRoutesToPaths;
