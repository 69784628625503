/**
 * Stores the event listener so it can be removed when unmounting the directive element, this way
 * performance is not (that) affected
 * It can be that it does not scale if there are multiple directive elements rendered at once,
 * although it should not be the case (another directive element rendered should be cause by a
 * click/touch, which would therefore free the variable by removing the previous listener)
 */
let handleClick;

/**
 * Directive that accepts a function as an argument and calls it whenever there is a click happening
 * outside of that same element
 */
export default {
  mounted(element, binding) {
    handleClick = (event) => {
      // When the directive element does not contain the clicked/touched element, excludes `Window`
      if (!event.composedPath().slice(0, -1).some((target) => element.contains(target))) {
        const callback = binding.value;

        callback();
      }
    };

    window.setTimeout(() => {
      document.addEventListener('mouseup', handleClick);
      document.addEventListener('touchstart', handleClick);

      document.querySelector('.document__pages')?.addEventListener('mousedown', handleClick);
    }, 10);
  },
  unmounted() {
    document.removeEventListener('mouseup', handleClick);
    document.removeEventListener('touchstart', handleClick);

    document.querySelector('.document__pages')?.removeEventListener('mousedown', handleClick);
  },
};
