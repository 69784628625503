import {
  post,
  put,
  remove,
} from '@/utils/request';

/**
 * Creates a decision making body
 *
 * @param {object} decisionMakingBody - Details of the decision making body to be created
 *
 * @returns {Promise} - Response promise
 */
export async function createDecisionMakingBody(decisionMakingBody) {
  const request = await post('/decisionmakingbodies', decisionMakingBody);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a decision making body
 *
 * @param {object} decisionMakingBody - Details of the decision making body to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function updateDecisionMakingBody(decisionMakingBody) {
  const request = await put(`/decisionmakingbodies/${decisionMakingBody.id}`, decisionMakingBody);
  const { promise } = await request;

  return promise;
}

/**
 * Deletes a decision making body
 *
 * @param {object} id - ID of the decision making body to be deleted
 *
 * @returns {Promise} - Response promise
 */
export async function deleteDecisionMakingBody(id) {
  const request = await remove(`/decisionmakingbodies/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Creates a voting threshold
 *
 * @param {object} votingThreshold - Details of the voting threshold to be created
 *
 * @returns {Promise} - Response promise
 */
export async function createVotingThreshold(votingThreshold) {
  const request = await post('/votingthresholds', votingThreshold);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a voting threshold
 *
 * @param {object} votingThreshold - Details of the voting threshold to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function updateVotingThreshold(votingThreshold) {
  const request = await put(`/votingthresholds/${votingThreshold.id}`, votingThreshold);
  const { promise } = await request;

  return promise;
}

/**
 * Deletes a voting threshold
 *
 * @param {object} id - ID of the voting threshold to be deleted
 *
 * @returns {Promise} - Response promise
 */
export async function deleteVotingThreshold(id) {
  const request = await remove(`/votingthresholds/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Creates a condition
 *
 * @param {object} condition - Details of the condition to be created
 *
 * @returns {Promise} - Response promise
 */
export async function createCondition(condition) {
  const request = await post('/conditions', condition);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a condition
 *
 * @param {object} condition - Details of the condition to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function updateCondition(condition) {
  const request = await put(`/conditions/${condition.id}`, condition);
  const { promise } = await request;

  return promise;
}

/**
 * Deletes a condition
 *
 * @param {object} id - ID of the condition to be deleted
 *
 * @returns {Promise} - Response promise
 */
export async function deleteCondition(id) {
  const request = await remove(`/conditions/${id}`);
  const { promise } = await request;

  return promise;
}
