export default {
  list: [],
  options: {
    company: [],
    fund: [],
    jurisdiction: [],
    documentType: [],
    assignee: [],
  },
  filters: {},
};
