import api from '@/utils/api';

const storeList = (commit, funds) => {
  if (!funds) {
    return;
  }

  commit('setList', funds);
  commit('setOptions', funds);
};

const storeCurrent = (commit, fund) => {
  if (!fund?.id) {
    return;
  }

  commit('setCurrent', fund);
};

export default {
  fetchAll: async ({ commit, getters }) => {
    // If the funds have not been loaded, they are load synchronously, blocking rendering
    if (!getters.list.length) {
      const funds = await api.funds.getAll();

      storeList(commit, funds);

      return;
    }

    api.funds.getAll().then((funds) => storeList(commit, funds));
  },
  applyFilters: ({ commit }, filters) => {
    commit('setFilters', filters);
  },
  fetchById: async ({ commit, getters }, id) => {
    // If the requested fund is not the stored one, it is loaded synchronously, blocking rendering
    if (getters.current.id !== parseInt(id, 10)) {
      const fund = await api.funds.getById(id);

      storeCurrent(commit, fund);

      return;
    }

    api.funds.getById(id).then((fund) => storeCurrent(commit, fund));
  },
  update: async ({ commit, dispatch }, fund) => {
    const {
      id,
      name,
      assignee,
    } = fund;

    const updated = await api.funds.updateById(id, { name, assignee });

    storeCurrent(commit, updated);

    if (updated?.id) {
      dispatch('feedback/createItem', { title: 'Fund updated correctly' }, { root: true });
    }
  },
  create: async ({ commit, dispatch }, fund) => {
    const created = await api.funds.create(fund);

    storeCurrent(commit, created);

    if (created?.id) {
      dispatch('feedback/createItem', { title: `Fund "${fund.name}" created correctly` }, { root: true });
    }

    // Returns the ID of the created fund so the user can be redirected to the correct detail
    return created.id;
  },
};
