<template>
  <nav class="navigation">
    <ul class="navigation__items">
      <li>
        <component
          class="navigation__home"
          :is="$route.path === '/funds' ? 'div' : 'router-link'"
          :to="{ path: '/' }"
        >
          <img
            class="navigation__logo"
            :src="logo"
            alt="Govin"
          />
        </component>
      </li>
      <li
        v-for="view in views"
        :key="view.name"
      >
        <component
          :class="[
            'navigation__item',
            ...(view.active ? ['navigation__item--active'] : []),
          ]"
          :is="view.active ? 'span' : 'router-link'"
          :to="view"
        >
          {{ view.name }}
        </component>
      </li>
    </ul>
    <div class="navigation__account">
      <span class="navigation__item navigation__item--user">
        {{ name }}
      </span>
      <button class="navigation__item" @click="signOut">
        Sign out
      </button>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue';

import { firebaseInstance, signOut } from '@/utils/auth';

import logo from '@/assets/images/logo.svg';

const views = [
  // 'Dashboard',
  'Funds',
  'Companies',
  // 'Academy',
  // 'Reviews',
  // 'Admin',
];

export default defineComponent({
  data: () => ({
    logo,
  }),
  computed: {
    name: () => firebaseInstance?.currentUser?.displayName,
    views() {
      return views.map((name) => ({ name, active: this.$route.name === name }));
    },
  },
  methods: {
    signOut,
  },
});
</script>

<style lang="scss" scoped>
@use 'utils/mixins/flex-align' as *;
@use 'utils/mixins/text' as *;

.navigation {
  --color-focus: var(--color-primary-light);
  --color-active: var(--color-primary);
  --color-user: var(--color-feedback-success);
  --height-logo: 4rem;

  @include text('lg', 'medium');

  display: flex;

  &__home,
  &__items,
  &__account {
    display: flex;
  }

  &__logo {
    @extend %mr-3;

    height: var(--height-logo);
  }

  &__item {
    @extend %px-2, %py-1;

    @include flex-align(center);

    height: 100%;

    &:not(&--active, &--user) {
      &:hover,
      &:focus {
        color: var(--color-focus);
        text-decoration: none;
      }
    }

    &--active,
    &--user {
      cursor: default;
    }

    &--active {
      @include text(null, 'bold');

      color: var(--color-active);
    }

    &--user {
      color: var(--color-user);
    }
  }

  &__account {
    @extend %ml-auto;
  }
}
</style>
