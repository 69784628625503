const files = require.context('./', true, /index\.js$/);

const modules = files.keys().reduce((aggregatedModules, fileName) => {
  const currentModule = files(fileName).default;

  if (!currentModule) {
    return aggregatedModules;
  }

  const storeModules = {
    ...aggregatedModules,
  };

  const moduleName = fileName.match(/^.\/([A-Za-z]+)\//)[1];
  storeModules[moduleName] = currentModule;

  return storeModules;
}, {});

export default modules;
