export default {
  list: [],
  current: {},
  options: {
    parent: [],
    status: [],
    fund: [],
    jurisdiction: [],
  },
  filters: {},
};
