import {
  get,
  put,
  post,
} from '@/utils/request';

const basePath = '/funds';

/**
 * Gets the list of all funds
 *
 * @returns {Promise} - Response promise
 */
export async function getAll() {
  const request = await get(basePath);
  const { promise } = await request;

  return promise;
}

/**
 * Gets a single fund by ID
 *
 * @param {number} id - ID of the fund to be retrieved
 *
 * @returns {Promise} - Response promise
 */
export async function getById(id) {
  const request = await get(`${basePath}/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a fund by ID
 *
 * @param {number} id - ID of the fund to be updated
 * @param {object} fund - Details of the fund to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function updateById(id, fund) {
  const request = await put(`${basePath}/${id}`, fund);
  const { promise } = await request;

  return promise;
}

/**
 * Creates a fund
 *
 * @param {object} fund - Details of the fund to be created
 *
 * @returns {Promise} - Response promise
 */
export async function create(fund) {
  const request = await post(basePath, fund);
  const { promise } = await request;

  return promise;
}
