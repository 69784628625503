import dateTime from '@/utils/dateTime';

export default {
  setCurrent: (state, document) => {
    state.current = {
      id: document.id,
      url: document.url,
      type: document.type,
      assignee: document.assignee?.id || '',
      reviewer: document.reviewer?.id || '',
      language: document.language,
      status: document.status,
      signedAt: {
        date: document.signedAt && dateTime.date.ISO(document.signedAt),
        time: document.signedAt && dateTime.time.ISO(document.signedAt).replace(/:\d{2,}$/, ''),
      },
      createdAt: dateTime.format(document.createdAt),
      updatedAt: dateTime.format(document.updatedAt),
      updatedBy: document.updatedBy?.name,
    };
  },
  setStakeholders: (state, stakeholders) => {
    state.stakeholders = stakeholders.map(({ id, name, description }) => ({
      id,
      name,
      originalText: description,
    }));
  },
};
