import {
  post,
  put,
  remove,
} from '@/utils/request';

const basePath = '/stakeholders';

/**
 * Creates a stakeholder
 *
 * @param {object} stakeholder - Details of the stakeholder to be created
 *
 * @returns {Promise} - Response promise
 */
export async function create(stakeholder) {
  const request = await post(basePath, stakeholder);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a stakeholder
 *
 * @param {object} stakeholder - Details of the stakeholder to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function update(stakeholder) {
  const request = await put(`${basePath}/${stakeholder.id}`, stakeholder);
  const { promise } = await request;

  return promise;
}

/**
 * Removes a stakeholder
 *
 * @param {object} id - ID of the stakeholder to be deleted
 *
 * @returns {Promise} - Response promise
 */
export async function deleteStakeholder(id) {
  const request = await remove(`${basePath}/${id}`);
  const { promise } = await request;

  return promise;
}
