export default [
  {
    path: '/sign-in',
    name: 'Sign in',
    component: () => import(/* webpackChunkName: "sign-in" */ './SignIn.vue'),
    meta: {
      isAuthRequired: false,
    },
  },
  {
    path: '/sign-out',
    name: 'Sign out',
    component: () => import(/* webpackChunkName: "sign-out" */ './SignOut.vue'),
    meta: {
      isAuthRequired: false,
    },
  },
];
