export default {
  setAll: (state, votingStructures) => {
    state.decisionMakingBodies = votingStructures;
  },
  extendDecisionMakingBodies: (state, decisionMakingBody) => {
    state.decisionMakingBodies = [...state.decisionMakingBodies, decisionMakingBody];
  },
  replaceDecisionMakingBody: (state, decisionMakingBody) => {
    state.decisionMakingBodies = state.decisionMakingBodies.map(
      (item) => (item.id === decisionMakingBody.id ? { ...item, ...decisionMakingBody } : item),
    );
  },
  deleteDecisionMakingBody: (state, decisionMakingBody) => {
    state.decisionMakingBodies = state.decisionMakingBodies.filter(
      ({ id }) => decisionMakingBody.id !== id,
    );
  },
  extendVotingThresholds: (state, { decisionMakingBody, votingThreshold }) => {
    const parent = state.decisionMakingBodies.find(({ id }) => decisionMakingBody.id === id);

    parent.votingThresholds = [...parent.votingThresholds, votingThreshold];
  },
  replaceVotingThreshold: (state, { decisionMakingBody, votingThreshold }) => {
    const parent = state.decisionMakingBodies.find(({ id }) => decisionMakingBody.id === id);

    parent.votingThresholds = parent.votingThresholds.map(
      (item) => (item.id === votingThreshold.id ? { ...item, ...votingThreshold } : item),
    );
  },
  deleteVotingThreshold: (state, { decisionMakingBody, votingThreshold }) => {
    const parent = state.decisionMakingBodies.find(({ id }) => decisionMakingBody.id === id);

    parent.votingThresholds = parent.votingThresholds.filter(
      ({ id }) => votingThreshold.id !== id,
    );
  },
  extendConditions: (state, { decisionMakingBody, votingThreshold, condition }) => {
    const grandParent = state.decisionMakingBodies.find(({ id }) => decisionMakingBody.id === id);
    const parent = grandParent.votingThresholds.find(({ id }) => votingThreshold.id === id);

    parent.conditions = [...parent.conditions, condition];
  },
  replaceCondition: (state, { decisionMakingBody, votingThreshold, condition }) => {
    const grandParent = state.decisionMakingBodies.find(({ id }) => decisionMakingBody.id === id);
    const parent = grandParent.votingThresholds.find(({ id }) => votingThreshold.id === id);

    parent.conditions = parent.conditions.map(
      (item) => (item.id === condition.id ? { ...item, ...condition } : item),
    );
  },
  deleteCondition: (state, { decisionMakingBody, votingThreshold, condition }) => {
    const grandParent = state.decisionMakingBodies.find(({ id }) => decisionMakingBody.id === id);
    const parent = grandParent.votingThresholds.find(({ id }) => votingThreshold.id === id);

    parent.conditions = parent.conditions.filter(
      ({ id }) => condition.id !== id,
    );
  },
};
