import { get } from '@/utils/request';

const basePath = '/users';

/**
 * Gets the list of all users
 *
 * @returns {Promise} - Response promise
 */
export default async function getAll() {
  const request = await get(basePath);
  const { promise } = await request;

  return promise;
}
