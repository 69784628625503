import {
  get,
  put,
  post,
} from '@/utils/request';

const basePath = '/documents';

/**
 * Gets a single document by ID
 *
 * @param {number} id - ID of the document to be retrieved
 *
 * @returns {Promise} - Response promise
 */
export async function getById(id) {
  const request = await get(`${basePath}/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a document by ID
 *
 * @param {number} id - ID of the document to be updated
 * @param {object} document - Details of the document to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function updateById(id, document) {
  const request = await put(`${basePath}/${id}`, document);
  const { promise } = await request;

  return promise;
}

/**
 * Creates an document
 *
 * @param {object} document - Details of the document to be created
 *
 * @returns {Promise} - Response promise
 */
export async function create(document) {
  const request = await post(`${basePath}`, document);
  const { promise } = await request;

  return promise;
}
