import globals from '@/globals';

import dateTime from '@/utils/dateTime';

/**
 * Returns the status type (used on the `Badge` component) of a document, based on the status string
 *
 * @param {string} status - Document status
 *
 * @returns {string} - Status type
 */
const getType = (status) => {
  if (status === 'CLOSED') {
    return 'success';
  }

  if (status === 'REVIEW') {
    return 'warning';
  }

  if (status === 'PROCESSING') {
    return 'default';
  }

  return 'danger';
};

/**
 * Returns the formatted status of a document, based on the status string
 *
 * @param {string} status - Document status
 *
 * @returns {object} - Object with the status label and the type (used on the `Badge` component)
 */
const getStatus = (status) => ({
  type: getType(status),
  label: globals.documentStatuses[status],
});

export default {
  setCurrent: (state, investment) => {
    state.current = {
      id: investment.id,
      name: investment.name,
      type: investment.type,
      createdAt: dateTime.format(investment.createdAt),
      updatedAt: dateTime.format(investment.updatedAt),
      updatedBy: investment.updatedBy?.name,
      documents: investment.documents?.map((document) => ({
        id: document.id,
        name: document.name,
        status: getStatus(document.status),
        type: globals.documentTypes[document.type],
        language: globals.languages[document.language],
        processing: document.status === 'PROCESSING',
        signedAt: document.signedAt ? dateTime.format(document.signedAt) : document.signedAt,
        assignee: document.assignee?.name,
      })),
    };
  },
};
