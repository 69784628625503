import { firebaseInstance } from '@/utils/auth';

// Map of active requests, with `key` being the `URL` and `value` being an object with the (JSON)
// serialized options, the request promise and a canceller method
const requests = {};

/**
 * Performs HTTP requests (using the native `fetch` API), provides a cancel method and caches
 * them so they are not fired multiple times
 *
 * @param {string} url - URL where to perform the request
 * @param {object} options - Options object (follows the native `fetch` API structure/requirements)
 *
 * @returns {object} - Object with the request promise and a method to cancel it
 */
export default async function request(url, options) {
  const optionsString = JSON.stringify(options);

  // A cached request is returned if exists (same URL and options)
  if (requests[url] && requests[url].options === optionsString) {
    const { promise, cancel } = requests[url];

    return {
      promise,
      cancel,
    };
  }

  const { signal, abort } = new AbortController();

  const token = await firebaseInstance.currentUser.getIdToken();

  const isForm = options?.body && options.body instanceof FormData;

  const headers = new Headers({
    ...(options?.headers),
    Authorization: `Bearer ${token}`,
  });

  if (!isForm) {
    headers.append('content-type', 'application/json');
  }

  const promise = fetch(`${process.env.VUE_APP_API_URL}${url}`, {
    ...options,
    ...(options?.body && { body: isForm ? options.body : JSON.stringify(options.body) }),
    headers,
    signal,
  }).then(async (data) => {
    try {
      const response = await data.json();
      const { status } = response;

      if (status >= 400) {
        const errors = {
          default: 'Oops, something went wrong',
          401: 'You do not have access to this environment',
          500: 'Something went wrong on the API',
        };

        throw new Error(errors[status] || errors.default);
      }

      return response;
    } catch (error) {
      window.alert(error.message);
    }

    return null;
  }).finally(() => {
    requests[url] = null;
  });

  const cancel = () => {
    abort();

    requests[url] = null;
  };

  requests[url] = {
    options: optionsString,
    promise,
    cancel,
  };

  return {
    promise,
    cancel,
  };
}
