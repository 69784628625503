export default {
  total: (state) => state.list.length,
  list: ({ list, filters }) => list.filter(({ searchable, status, jurisdiction, funds }) => (
    (!filters.search || searchable.some((value) => value.includes(filters.search)))
    && (!filters.status || status.label === filters.status)
    && (
      !filters.fund
      || (filters.fund === 'none' ? !funds : funds.includes(filters.fund))
    )
    && (!filters.jurisdiction || jurisdiction === filters.jurisdiction)
  )),
  options: (state) => state.options,
  current: (state) => state.current,
};
