import api from '@/utils/api';

export default {
  fetchAll: async ({ commit }) => {
    const documents = await api.reviews.default();

    if (documents) {
      commit('setList', documents);
      commit('setOptions', documents);
    }
  },
  applyFilters: ({ commit }, filters) => {
    commit('setFilters', filters);
  },
};
