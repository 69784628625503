export default [
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin-overview" */ './Overview.vue'),
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "admin-users" */ './Users.vue'),
  },
];
