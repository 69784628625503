<template>
  <nav v-if="breadcrumbs.length > 1">
    <ul class="breadcrumbs">
      <li
        :class="[
          'breadcrumb',
          ...(breadcrumb.active ? ['breadcrumb--active'] : []),
        ]"
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.name"
      >
        <component
          class="breadcrumb__link"
          :is="breadcrumb.active ? 'span' : 'router-link'"
          :to="breadcrumb"
        >
          {{ breadcrumb.name }}
        </component>
      </li>
    </ul>
  </nav>
</template>

<script>
import { defineComponent } from 'vue';

import { authorized as authorizedRoutes } from '@/router/routes';

import { findRoute } from '@/utils/router';

/**
 * Gets all the parts of the current path (i.e. `/a/:a/b/:b` becomes [`/a/:a`, `/b/:b`])
 *
 * @param {string} path - Path from which to get the parts
 *
 * @returns {[string]} - Array containing all path parts
 */
const getPathParts = (path) => path.match(/\/[a-z-]+(\/:\w+)?/ig) || [];

/**
 * Aggregates the parts of a path to build all possible paths (i.e. [`/a/:a`, `/b/:b`]
 * becomes [`/a/:a`, `/a/:a/b/:b`])
 *
 * @param {[string]} parts - All path parts to get aggregated
 *
 * @returns {[string]} - Array containing all possible paths
 */
const getPossiblePaths = (parts) => parts.reduce((paths, path) => [
  ...paths,
  `${paths.at(-1) || ''}${path}`,
], []);

/**
 * Finds if there is any existing route that matches the given paths, respecting per-route defined
 * exclusions (`route.meta.breadcrumb`)
 *
 * @param {[string]} paths - All possible paths
 *
 * @returns {[object]} - Array containing all matching routes
 */
const getRoutes = (paths) => paths.reduce((routes, path) => {
  const route = findRoute(authorizedRoutes, path);

  return [
    ...routes,
    ...(route && route.meta?.breadcrumb !== false ? [route] : []),
  ];
}, []);

export default defineComponent({
  computed: {
    breadcrumbs() {
      // All path parts (i.e. [`/a/:a`, `/b/:b`])
      const pathParts = getPathParts(this.$route.matched.at(-1).path);

      // All possible paths (i.e. [`/a/:a`, `/a/:a/b/:b`])
      const possiblePaths = getPossiblePaths(pathParts);

      // Existing routes matching the paths, respecting per-route defined exclusions
      const routes = getRoutes(possiblePaths);

      // Transforms each of the routes to the required format for the breadcrumb
      return routes.map((route) => {
        // Names of the parameters of this path (i.e. ['a', 'b'])
        const paramNames = (route.path.match(/:\w+/ig) || []).map((name) => name.slice(1));

        // Parameters object, mapping the parameter names with the values of the current route
        const parameters = paramNames.reduce((params, name) => {
          const param = this.$route.params[name];

          return {
            ...params,
            ...(param ? { [name]: param } : {}),
          };
        }, {});

        return {
          name: route.name,
          params: parameters,
          active: route.name === this.$route.name,
        };
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@use 'utils/mixins/text' as *;

.breadcrumbs {
  @extend %mx-auto, %mb-3;

  @include text('sm');

  display: flex;
  max-width: var(--width-container);
}

.breadcrumb {
  --color-active: var(--color-primary);

  &:not(:last-of-type)::after {
    @extend %mx-1;

    content: '/';
  }

  &:not(&--active) &__link {
    @include text(null, 'medium');

    color: var(--color-active);
  }
}
</style>
