export default [
  {
    path: '/academy',
    name: 'Academy',
    component: () => import(/* webpackChunkName: "academy" */ './Overview.vue'),
    meta: {
      breadcrumb: false,
    },
  },
  {
    path: '/academy/comments',
    name: 'Comments',
    component: () => import(/* webpackChunkName: "comments" */ './comments/List.vue'),
  },
  {
    path: '/academy/subjects/:companyType',
    name: 'Subjects',
    component: () => import(/* webpackChunkName: "subjects" */ './subjects/Detail.vue'),
    props: true,
  },
  {
    path: '/academy/subjects/:companyType/subject/:subjectId/annotations',
    name: 'Annotations',
    component: () => import(/* webpackChunkName: "annotations" */ './annotations/List.vue'),
    props: true,
  },
];
