import request from './private/request';

/**
 * Performs HTTP `DELETE` requests
 *
 * @param {string} url - URL where to perform the request
 * @param {object} body - Data for the request body
 *
 * @returns {object} - Object with the request promise and a method to cancel it
 */
export default (url, body) => request(url, { method: 'DELETE', body });
