/**
 * Returns the status of an entity, based on the completion percentage
 *
 * @param {number} percentage - Percentage of onboarding completion
 *
 * @returns {object} - Object with the status label and the type (used on the `Badge` component)
 */
export default (percentage) => ({
  label: percentage < 100 ? 'open' : 'closed',
  type: percentage < 100 ? 'danger' : 'success',
});
