export default [
  {
    path: '/funds',
    alias: '/',
    name: 'Funds',
    component: () => import(/* webpackChunkName: "funds" */ './List.vue'),
  },
  {
    path: '/funds/create',
    name: 'Create fund',
    component: () => import(/* webpackChunkName: "create-fund" */ './Form.vue'),
  },
  {
    path: '/fund/:fundId',
    name: 'Fund',
    component: () => import(/* webpackChunkName: "fund" */ './Detail.vue'),
    props: true,
  },
];
