/**
 * This file exposes constants that will be used in different places accross the application.
 *
 * This variables are not exposed on the `window` object to keep them private and avoid pollution.
 */

const projectName = 'Onboarding';

const jurisdictions = {
  NL: 'The Netherlands',
  UK: 'United Kingdom',
  US: 'United States',
};

const languages = {
  NL: 'Dutch',
  EN: 'English',
};

const investmentTypes = {
  PRE_SEED: 'Pre-seed',
  SEED: 'Seed',
  SERIES_A: 'Series A',
  SERIES_B: 'Series B',
  SERIES_C: 'Series C',
};

const documentTypes = {
  AOA: 'Articles of association',
  SHA: 'Shareholders agreement',
  RM: 'Reserved matters',
};

const documentStatuses = {
  OPEN: 'open',
  REVIEW: 'review',
  CLOSED: 'closed',
  PROCESSING: 'processing',
};

const categoryColors = [
  '#fbd206',
  '#feaf8a',
  '#fd7a8c',
  '#cc89d6',
  '#bfcff0',
  '#9ce7c9',
  '#4dc656',
  '#d4a7e7',
];

const financialThresholdOperators = {
  EQUALS: '=',
  GREATER_THAN: '>',
  SMALLER_THAN: '<',
  GREATER_OR_EQUAL_TO: '≥',
  SMALLER_OR_EQUAL_TO: '≤',
};

export default {
  projectName,
  jurisdictions,
  languages,
  investmentTypes,
  documentTypes,
  documentStatuses,
  categoryColors,
  financialThresholdOperators,
};
