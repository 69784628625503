import api from '@/utils/api';

export default {
  populate: ({ commit }, votingStructures) => {
    commit('setAll', votingStructures);
  },
  createDecisionMakingBody: async ({ commit, dispatch, rootGetters }, decisionMakingBody) => {
    const created = await api.votingStructures.createDecisionMakingBody({
      name: decisionMakingBody.name,
      document: rootGetters['documents/current'].id,
    });

    if (created.id) {
      commit('extendDecisionMakingBodies', created);

      dispatch('feedback/createItem', { title: `Decision making body "${decisionMakingBody.name}" created correctly` }, { root: true });
    }
  },
  updateDecisionMakingBody: async ({ commit, dispatch }, decisionMakingBody) => {
    const updated = await api.votingStructures.updateDecisionMakingBody(decisionMakingBody);

    if (updated.id) {
      commit('replaceDecisionMakingBody', updated);

      dispatch('feedback/createItem', { title: `Decision making body "${decisionMakingBody.name}" updated correctly` }, { root: true });
    }
  },
  deleteDecisionMakingBody: async ({ commit, dispatch }, decisionMakingBody) => {
    const response = await api.votingStructures.deleteDecisionMakingBody(decisionMakingBody.id);

    if (response.deleted) {
      commit('deleteDecisionMakingBody', decisionMakingBody);

      dispatch('feedback/createItem', { title: `Decision making body "${decisionMakingBody.name}" deleted correctly`, message: 'All its voting thresholds and conditions have been deleted as well' }, { root: true });
    }
  },
  createVotingThreshold: async ({ commit, dispatch }, options) => {
    const { decisionMakingBody, votingThreshold } = options;

    const created = await api.votingStructures.createVotingThreshold({
      decisionMakingBody: decisionMakingBody.id,
      ...votingThreshold,
    });

    if (created.id) {
      commit('extendVotingThresholds', {
        decisionMakingBody,
        votingThreshold: created,
      });

      dispatch('feedback/createItem', { title: `Voting threshold "${votingThreshold.name}" created correctly` }, { root: true });
    }
  },
  updateVotingThreshold: async ({ commit, dispatch }, options) => {
    const { decisionMakingBody, votingThreshold } = options;

    const updated = await api.votingStructures.updateVotingThreshold({
      id: votingThreshold.id,
      name: votingThreshold.name,
      type: votingThreshold.type,
    });

    if (updated.id) {
      commit('replaceVotingThreshold', {
        decisionMakingBody,
        votingThreshold: updated,
      });

      dispatch('feedback/createItem', { title: `Voting threshold "${votingThreshold.name}" updated correctly` }, { root: true });
    }
  },
  deleteVotingThreshold: async ({ commit, dispatch }, options) => {
    const { votingThreshold } = options;

    const response = await api.votingStructures.deleteVotingThreshold(votingThreshold.id);

    if (response.deleted) {
      commit('deleteVotingThreshold', options);

      dispatch('feedback/createItem', { title: `Voting threshold "${votingThreshold.name}" deleted correctly`, message: 'All its conditions have been deleted as well' }, { root: true });
    }
  },
  createCondition: async ({ commit, dispatch }, options) => {
    const { decisionMakingBody, votingThreshold, condition } = options;

    const created = await api.votingStructures.createCondition({
      votingThreshold: votingThreshold.id,
      ...condition,
    });

    if (created.id) {
      commit('extendConditions', {
        decisionMakingBody,
        votingThreshold,
        condition: created,
      });

      dispatch('feedback/createItem', { title: 'Condition created correctly' }, { root: true });
    }
  },
  updateCondition: async ({ commit, dispatch }, options) => {
    const { decisionMakingBody, votingThreshold, condition } = options;

    const updated = await api.votingStructures.updateCondition(condition);

    if (updated.id) {
      commit('replaceCondition', {
        decisionMakingBody,
        votingThreshold,
        condition: updated,
      });

      dispatch('feedback/createItem', { title: 'Condition updated correctly' }, { root: true });
    }
  },
  deleteCondition: async ({ commit, dispatch }, options) => {
    const response = await api.votingStructures.deleteCondition(options.condition.id);

    if (response.deleted) {
      commit('deleteCondition', options);

      dispatch('feedback/createItem', { title: 'Condition deleted correctly' }, { root: true });
    }
  },
};
