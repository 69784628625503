const files = require.context('./', false, /^(?!.*index).*\.js$/);

const domains = files.keys().reduce((aggregatedDomains, fileName) => {
  const currentDomain = files(fileName);

  if (!currentDomain) {
    return aggregatedDomains;
  }

  const apiDomains = {
    ...aggregatedDomains,
  };

  const domainName = fileName.match(/^.\/([A-Za-z]+)/)[1];
  apiDomains[domainName] = currentDomain;

  return apiDomains;
}, {});

export default domains;
