export default {
  total: (state) => state.list.length,
  list: ({ list, filters }) => list.filter(({ searchable, assignee, status }) => (
    (!filters.search || searchable.some((value) => value.includes(filters.search)))
    && (
      !filters.assignee
      || (filters.assignee === 'none' ? !assignee : assignee === filters.assignee)
    )
    && (!filters.status || status.label === filters.status)
  )),
  options: (state) => state.options,
  current: (state) => state.current,
};
