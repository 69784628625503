import globals from '@/globals';

import { getPageTitle } from '@/utils/application';

export default function created() {
  // `this` is the `Vue` component instance
  const title = getPageTitle(this);

  if (title) {
    document.title = `Govin ${globals.projectName} - ${title}`;
  }
}
