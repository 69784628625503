import api from '@/utils/api';

import store from '@/store';

const formatAnnotation = (annotation) => {
  const { financialThreshold } = annotation;

  const hasFinancialThreshold = !Number.isNaN(parseInt(financialThreshold.amount, 10));

  const subject = store.getters['subjects/list'].find(({ id }) => annotation.subject[0] === id);
  const isNonVotable = subject?.name ? /nonvotables/i.test(subject.name) : false;

  return {
    id: annotation.id === 'new' ? undefined : annotation.id,
    ...(annotation.suggestion ? { status: 'ACCEPTED' } : {}),
    document: annotation.document,
    article: annotation.article,
    // The API only receives one subject (the last one)
    subject: annotation.subject.at(-1),
    highlights: annotation.highlights.map((highlight) => ({
      id: highlight.id,
      page: highlight.page,
      rectangles: highlight.rectangles,
      text: highlight.text,
    })),
    financialThreshold: hasFinancialThreshold ? { ...financialThreshold, currency: 'EURO' } : null,
    votingThresholds: isNonVotable ? [] : annotation.votingThresholds.map(
      (annotationVotingThreshold) => {
        const { id, votingThreshold, override } = annotationVotingThreshold;

        const conditions = [];

        if (override.percentage.value !== undefined) {
          conditions.push(override.percentage);
        }

        if (override.quorum.value !== undefined || override.quorum.type) {
          conditions.push({
            ...override.quorum,
            type: override.quorum.type || null,
            value: override.quorum.value || null,
          });
        }

        return {
          id,
          votingThresholdId: votingThreshold.id,
          conditions,
        };
      },
    ),
  };
};

export default {
  populate: ({ commit, getters }, { annotations, stakeholders }) => {
    const highlights = [...annotations, ...stakeholders];
    const serializedHighlights = JSON.stringify(highlights);

    if (getters.serialized !== serializedHighlights) {
      commit('setSerialized', serializedHighlights);
      commit('setAnnotations', annotations);
      commit('setStakeholders', stakeholders);
    }
  },
  createStakeholder: async ({ commit, dispatch, rootGetters }, stakeholder) => {
    const created = await api.stakeholders.create({
      investment: stakeholder.investment,
      name: stakeholder.name,
      description: stakeholder.description,
      definitions: stakeholder.highlights.map((highlight) => ({
        documentId: rootGetters['documents/current'].id,
        highlights: [highlight],
      })),
    });

    if (created.id) {
      commit('extendStakeholders', created);

      dispatch('feedback/createItem', { title: `Stakeholder "${stakeholder.name}" created correctly` }, { root: true });
    }

    return created;
  },
  updateStakeholder: async ({ commit, dispatch }, stakeholder) => {
    const updated = await api.stakeholders.update({
      id: stakeholder.id,
      name: stakeholder.name,
    });

    if (updated.id) {
      commit('replaceStakeholder', updated);

      dispatch('feedback/createItem', { title: `Stakeholder "${stakeholder.name}" updated correctly` }, { root: true });
    }

    return updated;
  },
  deleteStakeholder: async ({ commit, dispatch }, stakeholder) => {
    const response = await api.stakeholders.deleteStakeholder(stakeholder.id);

    if (response.deleted) {
      commit('deleteStakeholder', stakeholder);

      dispatch('feedback/createItem', { title: `Stakeholder "${stakeholder.name}" deleted correctly` }, { root: true });
    }
  },
  createAnnotation: async ({ commit, dispatch }, annotation) => {
    const created = await api.annotations.create(formatAnnotation(annotation));

    if (created.id) {
      commit('extendAnnotations', created);

      dispatch('feedback/createItem', { title: `Annotation of article "${annotation.article}" created correctly` }, { root: true });
    }

    return created;
  },
  updateAnnotation: async ({ commit, dispatch }, annotation) => {
    const updated = await api.annotations.update(formatAnnotation(annotation));

    if (updated.id) {
      commit('replaceAnnotation', updated);

      dispatch('feedback/createItem', { title: `Annotation of article "${annotation.article}" updated correctly` }, { root: true });
    }

    return updated.id;
  },
  acceptAnnotation: async ({ commit, dispatch }, annotation) => {
    const updated = await api.annotations.update(formatAnnotation(annotation));

    if (updated.id) {
      commit('replaceAnnotation', updated);

      dispatch('feedback/createItem', { title: 'Suggested annotation accepted correctly' }, { root: true });
    }

    return updated.id;
  },
  deleteAnnotation: async ({ commit, dispatch }, annotation) => {
    const response = await api.annotations.deleteAnnotation(annotation.id);

    if (response.deleted) {
      commit('deleteAnnotation', annotation);

      if (annotation.suggestion) {
        dispatch('feedback/createItem', { title: 'Suggested annotation rejected correctly' }, { root: true });
        return;
      }

      dispatch('feedback/createItem', { title: `Annotation of article "${annotation.article}" deleted correctly` }, { root: true });
    }
  },
  // TODO: can this be done in `updateAnnotation`?
  removeAnnotationVotingThreshold: async ({ commit }, options) => {
    const { id } = options.annotationVotingThreshold;
    const response = await api.annotations.removeVotingThreshold(id);

    if (response.deleted) {
      commit('removeAnnotationVotingThreshold', options);
    }
  },
  saveComment: async ({ commit, dispatch }, { annotation, text }) => {
    const comment = await api.annotations.createComment(annotation.id, { text });

    if (comment.id) {
      commit('extendAnnotationComments', { annotation, comment });

      dispatch('feedback/createItem', { title: 'Comment saved correctly' }, { root: true });
    }
  },
};
