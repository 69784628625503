import api from '@/utils/api';

const storeList = (commit, companies) => {
  if (!companies) {
    return;
  }

  commit('setList', companies);
  commit('setOptions', companies);
};

const storeCurrent = (commit, company) => {
  if (!company?.id) {
    return;
  }

  commit('setCurrent', company);
};

export default {
  fetchAll: async ({ commit, getters }) => {
    // If the companies have not been loaded, they are load synchronously, blocking rendering
    if (!getters.list.length) {
      const companies = await api.companies.getAll();

      storeList(commit, companies);

      return;
    }

    api.companies.getAll().then((companies) => storeList(commit, companies));
  },
  applyFilters: ({ commit }, filters) => {
    commit('setFilters', filters);
  },
  fetchById: async ({ commit, getters }, id) => {
    // If the requested company is not the stored one, it loads synchronously, blocking rendering
    if (getters.current.id !== parseInt(id, 10)) {
      const company = await api.companies.getById(id);

      storeCurrent(commit, company);

      return;
    }

    api.companies.getById(id).then((company) => storeCurrent(commit, company));
  },
  update: async ({ commit, dispatch }, company) => {
    const {
      id,
      name,
      jurisdiction,
    } = company;

    const updated = await api.companies.updateById(id, { name, jurisdiction });

    storeCurrent(commit, updated);

    if (updated?.id) {
      dispatch('feedback/createItem', { title: 'Company updated correctly' }, { root: true });

      company.parents.forEach((fund) => dispatch('funds/fetchById', fund, { root: true }));
    }
  },
  create: async ({ commit, dispatch }, company) => {
    const created = await api.companies.create(company);

    storeCurrent(commit, company);

    if (created?.id) {
      dispatch('feedback/createItem', { title: `Company "${created.name}" created correctly` }, { root: true });

      company.funds.forEach((fund) => dispatch('funds/fetchById', fund, { root: true }));
    }

    // Returns the ID of the created company so the user can be redirected to the correct detail
    return created?.id;
  },
};
