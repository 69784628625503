import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const initialState = () => ({
  list: [],
});

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
