<template>
  <div class="feedback-messages">
    <Feedback
      v-for="feedback in messages"
      :key="feedback.id"
      :type="feedback.type"
      :title="feedback.title"
      :close="feedback.persistent ? undefined : dismiss(feedback.id)"
    >
      <div v-if="feedback.message">
        {{ feedback.message }}
      </div>
    </Feedback>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import store from '@/store';

import Feedback from './private/Feedback.vue';

export default defineComponent({
  components: {
    Feedback,
  },
  computed: {
    ...mapGetters({
      messages: 'feedback/list',
    }),
  },
  methods: {
    dismiss: (id) => () => store.dispatch('feedback/dismiss', id),
  },
});
</script>

<style lang="scss" scoped>
@use 'config/spacing' as *;

@use 'utils/mixins/z' as *;

.feedback-messages {
  @extend %p-2;
  @include z('layout', 'feedback');

  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: $s-sm;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  width: 600px;
}
</style>
