import {
  get,
  put,
  post,
} from '@/utils/request';

const basePath = '/companies';

/**
 * Gets the list of all companies
 *
 * @returns {Promise} - Response promise
 */
export async function getAll() {
  const request = await get(`${basePath}`);
  const { promise } = await request;

  return promise;
}

/**
 * Gets a single company by ID
 *
 * @param {number} id - ID of the company to be retrieved
 *
 * @returns {Promise} - Response promise
 */
export async function getById(id) {
  const request = await get(`${basePath}/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a company by ID
 *
 * @param {number} id - ID of the company to be updated
 * @param {object} company - Details of the company to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function updateById(id, company) {
  const request = await put(`${basePath}/${id}`, company);
  const { promise } = await request;

  return promise;
}

/**
 * Creates a company
 *
 * @param {object} company - Details of the company to be created
 *
 * @returns {Promise} - Response promise
 */
export async function create(company) {
  const request = await post(`${basePath}`, company);
  const { promise } = await request;

  return promise;
}
