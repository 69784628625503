import { get } from '@/utils/request';

/**
 * Gets the list of all documents to be reviewed
 *
 * @returns {Promise} - Response promise
 */
export default async function getAll() {
  const request = await get('/reviews.json');
  const { promise } = await request;

  return promise;
}
