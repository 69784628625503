import { createApp } from 'vue';

import { firebaseInstance } from '@/utils/auth';

import directives from '@/directives';
import mixins from '@/mixins';

import '@/styles/setup.scss';

import App from '@/App.vue';

import router from '@/router';
import store from '@/store';

let app;

// Does not render the app until the `firebase` instance is available
firebaseInstance.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);

    app.config.devtools = true;

    app.mixin(mixins);

    app
      .use(store)
      .use(router);

    Object.entries(directives).forEach(([name, directive]) => app.directive(name, directive));

    app.mount('#app');
  }
});
