import { get } from '@/utils/request';

/**
 * Gets the list of the most recent unresolved comments
 *
 * @returns {Promise} - Response promise
 */
export async function getLatest() {
  const request = await get('/latestComments.json');
  const { promise } = await request;

  return promise;
}

/**
 * Gets the list of all comments
 *
 * @returns {Promise} - Response promise
 */
export async function getAll() {
  const request = await get('/comments.json');
  const { promise } = await request;

  return promise;
}
