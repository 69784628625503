import request from './private/request';

/**
 * Performs HTTP `POST` requests
 *
 * @param {string} url - URL where to perform the request
 * @param {object} body - Data for the request body
 *
 * @returns {object} - Object with the request promise and a method to cancel it
 */
export default (url, body) => request(url, {
  method: 'POST',
  body: Object.values(body).some((value) => value instanceof File)
    ? Object.entries(body).reduce((form, [key, value]) => {
      form.append(key, value);

      return form;
    }, new FormData())
    : body,
});
