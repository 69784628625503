import api from '@/utils/api';

export default {
  fetchAll: async ({ commit, getters }) => {
    // If the subjects have not been loaded, they are load synchronously, blocking rendering
    if (!getters.list.length) {
      const subjects = await api.subjects.default();

      if (subjects) {
        commit('setList', subjects);
      }

      return;
    }

    api.subjects.default().then((subjects) => {
      if (subjects) {
        commit('setList', subjects);
      }
    });
  },
};
