import globals from '@/globals';

import api from '@/utils/api';
import dateTime from '@/utils/dateTime';

export default {
  fetchById: async ({ commit, dispatch, getters }, id) => {
    if (getters.current.id === parseInt(id, 10)) {
      return;
    }

    const document = await api.documents.getById(id);

    if (document) {
      commit('setCurrent', document);
      commit('setStakeholders', document.stakeholders);

      // `highlights` depends on `votingStructures`, therefore order matters
      dispatch('votingStructures/populate', document.decisionMakingBodies, { root: true });

      const { annotations, stakeholders } = document;
      dispatch('highlights/populate', { annotations, stakeholders }, { root: true });
    }
  },
  update: async ({ commit, dispatch }, { companyId, investmentId, document }) => {
    const { date, time } = document.signedAt;

    const signedAt = document.signedAt.date ? `${date}T${time || '00:00'}:00.000Z` : null;

    const updated = await api.documents.updateById(document.id, {
      type: document.type,
      assignee: document.assignee,
      reviewer: document.reviewer,
      language: document.language,
      status: document.status,
      signedAt: signedAt && dateTime.API(signedAt),
    });

    if (updated) {
      await commit('setCurrent', updated);

      dispatch('feedback/createItem', { title: 'Document updated correctly' }, { root: true });

      dispatch('companies/fetchById', companyId, { root: true });
      dispatch('investments/fetchById', investmentId, { root: true });
    }
  },
  create: async ({ commit, dispatch }, document) => {
    const created = await api.documents.create(document);

    if (created.id) {
      commit('setCurrent', created);

      dispatch('feedback/createItem', { title: `Document "${globals.documentTypes[document.type]}" created correctly` }, { root: true });

      dispatch('companies/fetchById', document.company, { root: true });
      await dispatch('investments/fetchById', document.investment, { root: true });
    }

    return created.id;
  },
};
