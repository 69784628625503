const formatter = new Intl.RelativeTimeFormat('en-NL', { style: 'long' });

const units = [
  { name: 'seconds', amount: 60 },
  { name: 'minutes', amount: 60 },
  { name: 'hours', amount: 24 },
  { name: 'days', amount: 7 },
  { name: 'weeks', amount: 4.34524 },
  { name: 'months', amount: 12 },
  { name: 'years', amount: Number.POSITIVE_INFINITY },
];

/**
 * Returns a formatted moment string based on the time between the date and now
 *
 * @param {object} dateTime - Date with time to be formatted
 *
 * @returns {string} - Formatted moment
 */
export default (dateTime) => {
  // Converts to second difference
  let duration = (new Date(dateTime) - new Date()) / 1000;

  // In case the duration is less than 1 second it falls back to `just now`
  if (duration > -1 && duration < 1) {
    return 'just now';
  }

  // Iterates through all possible difference units, from small to large
  for (let index = 0; index <= units.length; index += 1) {
    const unit = units[index];

    // In case that the time difference is smaller, then it means that it's the right unit type
    // (i.e. a difference of `61` should be `1 minute ago` instead of `61 seconds ago`)
    if (Math.abs(duration) < unit.amount) {
      return formatter.format(Math.round(duration), unit.name);
    }

    // The duration gets divided by the amount of the current unit, for the next iteration
    duration /= unit.amount;
  }

  // As a fallback, it returns a generic string
  return 'long time ago';
};
