import dateTime from '@/utils/dateTime';

const formatComment = (comment) => ({
  ...comment,
  subjects: comment.subjects.map((subject) => subject.name).join(' / '),
  resolvedAt: comment.resolvedAt && dateTime.relative(comment.resolvedAt),
  createdAt: dateTime.relative(comment.createdAt),
});

export default {
  setLatest: (state, comments) => {
    state.latest = comments.map(formatComment);
  },
  setList: (state, comments) => {
    state.list = comments.map((comment) => ({
      ...formatComment(comment),
      searchable: [
        comment.text,
        ...(comment.resolvedBy ? [comment.resolvedBy.name] : []),
        comment.createdBy.name,
        comment.company.name,
        comment.investment.name,
        comment.document.type,
        ...comment.subjects.map((subject) => subject.name),
      ].map((value) => value.toLowerCase()),
    }));
  },
  setOptions: (state, comments) => {
    // Finds all the unique companies on the list of comments and formats them for the options of
    // the `FormControl` component
    state.options.company = comments.reduce((options, { company }) => {
      if (options.some((option) => option.value === company.id) || !company) {
        return options;
      }

      return [
        ...options,
        {
          label: company.name,
          value: company.id,
        },
      ];
    }, []);

    // Finds all the unique jurisdictions on the list of comments and formats them for the options
    // of the `FormControl` component
    state.options.jurisdiction = comments.reduce((options, { company }) => {
      if (options.some((option) => option.value === company.jurisdiction)) {
        return options;
      }

      return [
        ...options,
        {
          label: company.jurisdiction,
          value: company.jurisdiction,
        },
      ];
    }, []);

    // Finds all the unique document types on the list of comments and formats them for the options
    // of the `FormControl` component
    state.options.documentType = comments.reduce((options, { document }) => {
      if (options.some((option) => option.value === document.type) || !document) {
        return options;
      }

      return [
        ...options,
        {
          label: document.type,
          value: document.type,
        },
      ];
    }, []);
  },
  setFilters: (state, filters) => {
    state.filters = { ...filters };
  },
};
