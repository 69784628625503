import {
  get,
  put,
  post,
} from '@/utils/request';

const basePath = '/investments';

/**
 * Gets a single investment by ID
 *
 * @param {number} id - ID of the investment to be retrieved
 *
 * @returns {Promise} - Response promise
 */
export async function getById(id) {
  const request = await get(`${basePath}/${id}`);
  const { promise } = await request;

  return promise;
}

/**
 * Updates a investment by ID
 *
 * @param {number} id - ID of the investment to be updated
 * @param {object} investment - Details of the investment to be updated
 *
 * @returns {Promise} - Response promise
 */
export async function updateById(id, investment) {
  const request = await put(`${basePath}/${id}`, investment);
  const { promise } = await request;

  return promise;
}

/**
 * Creates an investment
 *
 * @param {object} investment - Details of the investment to be created
 *
 * @returns {Promise} - Response promise
 */
export async function create(investment) {
  const request = await post(`${basePath}`, investment);
  const { promise } = await request;

  return promise;
}
