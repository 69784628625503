import config from './config';

const helpers = {
  getMessageString: ({ type, message }) => {
    if (message) {
      return message;
    }

    return type === 'error' ? config.defaultError : config.defaultSuccess;
  },
  getTimeout: (callback) => (
    window.setTimeout(callback, config.dismissTimeoutSeconds * 1000)
  ),
};

/**
 * Returns an action function that dispatches a `createItem` action with an injected type, so it
 * can be reused for multiple actions
 *
 * @param {string} type - Feedback message type
 *
 * @returns {function} - Action function to dispatch a `createItem` action
 */
const dispatchCreateItem = (type) => (
  ({ dispatch }, feedback) => dispatch(
    'createItem',
    {
      type,
      message: helpers.getMessageString({ type, message: feedback.message }),
      persistent: !!feedback.persistent,
    },
  )
);

/**
 * Returns an action function that dispatches another action based on the injected action name, so
 * it can be reused for multiple actions
 *
 * @param {string} actionName - Name of the action to dispatch
 *
 * @returns {function} - Action function to dispatch an action of the specified name
 */
const dispatchPersistentAction = (actionName) => (
  ({ dispatch }, message) => dispatch(
    actionName,
    {
      message,
      persistent: true,
    },
  )
);

export default {
  /**
   * Creates a new feedback item based on the param or updates its timeout in case it already exists
   * and it's not persistent
   *
   * @param {object} feedback - Feedback object to be created/updated
   */
  createItem: ({ commit, dispatch, getters }, feedback) => {
    const existingFeedback = getters.getItemByTypeAndMessage(feedback.type || 'success', feedback.message);
    const id = existingFeedback?.id || new Date().getTime();
    const callback = () => dispatch('dismiss', id);

    if (existingFeedback) {
      if (!feedback.persistent) {
        window.clearTimeout(existingFeedback.timeout);

        commit('updateTimeout', id, helpers.getTimeout(callback));
      }

      return;
    }

    const newFeedback = {
      id,
      ...feedback,
      ...(!feedback.persistent && { timeout: helpers.getTimeout(callback) }),
    };

    commit('addItem', newFeedback);
  },
  /**
   * Removes a feedback item in case it's found based on the given ID, also it clears it's timeout
   * in case it's set
   *
   * @param {number} id - ID of the feedback item to be removed
   */
  dismiss: ({ commit, getters }, id) => {
    const feedback = getters.getItemById(id);
    const feedbackIndex = getters.getItemIndex(feedback);

    if (feedback?.timeout) {
      window.clearTimeout(feedback.timeout);
    }

    if (feedbackIndex >= 0) {
      commit('removeItem', feedbackIndex);
    }
  },
  dismissAll: ({ dispatch, getters }) => {
    const { list } = getters;

    while (list.length > 0) {
      const item = list[0];

      dispatch('dismiss', item.id);
    }
  },
  showError: dispatchCreateItem('error'),
  showPersistentError: dispatchPersistentAction('showError'),
  showSuccess: dispatchCreateItem('success'),
  showPersistentSuccess: dispatchPersistentAction('showSuccess'),
};
