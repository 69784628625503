const formatHighlight = (pages, item) => {
  item.highlights.forEach(({ id, page: pageNumber, rectangles }) => {
    const page = pages.get(pageNumber);

    const highlightBoundaries = rectangles.reduce(
      (boundaries, line) => ({
        top: boundaries.top < line.top ? boundaries.top : line.top,
        bottom: boundaries.bottom < line.bottom ? boundaries.bottom : line.bottom,
      }),
      { top: Infinity, bottom: Infinity },
    );

    const highlight = {
      id,
      annotation: {
        id: item.id,
        subject: item.subject,
        suggestion: item.suggestion,
      },
      rectangles,
      'z-index': Math.round(highlightBoundaries.top + highlightBoundaries.bottom),
    };

    if (page) {
      page.push(highlight);
    } else {
      pages.set(pageNumber, [highlight]);
    }
  });

  return pages;
};

export default {
  list: (state, { annotations, stakeholders }) => (
    [...annotations, ...stakeholders].reduce(formatHighlight, new Map())
  ),
  serialized: ({ serialized }) => serialized,
  annotations: ({ annotations }) => annotations,
  stakeholders: ({ stakeholders }, getters, rootState, rootGetters) => (
    stakeholders.reduce((list, stakeholder) => {
      const documentDefinitions = stakeholder.definitions.reduce((definitions, definition) => {
        if (definition.documentId !== rootGetters['documents/current'].id) {
          return definitions;
        }

        return [
          ...definitions,
          ...definition.highlights,
        ];
      }, []);

      if (!documentDefinitions.length) {
        return list;
      }

      return [
        ...list,
        {
          ...stakeholder,
          subject: ['stakeholder'],
          highlights: documentDefinitions.map((highlights) => ({
            ...highlights,
            annotation: {
              id: stakeholder.id,
              subject: ['stakeholder'],
            },
          })),
        },
      ];
    }, [])
  ),
};
