/**
 * Returns a formatted date with time
 *
 * @param {object} dateTime - Date with time to be formatted
 *
 * @returns {string} - Formatted date with time
 */
export default (dateTime) => new Date(dateTime).toLocaleString('en-NL', {
  dateStyle: 'medium',
  timeStyle: 'medium',
});
