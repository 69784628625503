import globals from '@/globals';

import { getStatus, getCompletion } from '@/utils/completion';
import dateTime from '@/utils/dateTime';

export default {
  setList: (state, companies) => {
    state.list = companies.map((company) => {
      const { closedDocuments } = company;

      const totalDocuments = company.openDocuments + closedDocuments;
      const percentage = parseFloat(closedDocuments / totalDocuments) * 100;

      const status = getStatus(percentage);
      const completion = getCompletion(closedDocuments, totalDocuments, percentage);

      return {
        id: company.id,
        name: company.name,
        status,
        funds: company.funds.map((fund) => fund.name).join(', '),
        completion,
        jurisdiction: globals.jurisdictions[company.jurisdiction],
        searchable: [
          company.name,
          status.label,
          completion.label,
          completion.title,
          ...(company.funds || []).map((fund) => fund.name),
          company.jurisdiction,
          globals.jurisdictions[company.jurisdiction],
        ].map((value) => value.toLowerCase()),
      };
    });
  },
  setOptions: (state, companies) => {
    // Finds all the companies that do not belong to a fund and formats them for the options of the
    // `FormControl` component
    state.options.parent = companies.reduce((options, company) => {
      if (company.funds.length) {
        return options;
      }

      return [
        ...options,
        {
          label: company.name,
          value: company.id,
        },
      ];
    }, []);

    // Finds all the unique statuses on the formatted list of companies and formats them for the
    // options of the `FormControl` component
    state.options.status = state.list.reduce((options, { status }) => {
      if (options.some((option) => option.value === status.label)) {
        return options;
      }

      return [
        ...options,
        {
          label: `${status.label[0].toUpperCase()}${status.label.slice(1)}`,
          value: status.label,
        },
      ];
    }, []);

    // Finds all the unique funds on the list of companies and formats them for the options of the
    // `FormControl` component
    state.options.fund = companies.reduce((options, company) => {
      const funds = company.funds.filter((fund) => (
        options.every((option) => option.value !== fund.name)
      ));

      return [
        ...options,
        ...funds.map(({ name }) => ({
          label: name,
          value: name,
        })),
      ];
    }, []);

    // Finds all the unique jurisdictions on the list of companies and formats them for the options
    // of the `FormControl` component
    state.options.jurisdiction = companies.reduce((options, company) => {
      const jurisdiction = globals.jurisdictions[company.jurisdiction];

      if (options.some((option) => option.value === jurisdiction)) {
        return options;
      }

      return [
        ...options,
        {
          label: jurisdiction,
          value: jurisdiction,
        },
      ];
    }, []);
  },
  setFilters: (state, filters) => {
    state.filters = { ...filters };
  },
  setCurrent: (state, company) => {
    state.current = {
      id: company.id,
      name: company.name,
      jurisdiction: company.jurisdiction,
      funds: company.funds?.map((fund) => fund.name).join(', '),
      parents: company.funds?.map((fund) => fund.id),
      createdAt: dateTime.format(company.createdAt),
      updatedAt: dateTime.format(company.updatedAt),
      updatedBy: company.updatedBy?.name,
      investments: (company.investments || []).map((investment) => {
        const { closedDocuments } = investment;

        const totalDocuments = investment.openDocuments + closedDocuments;
        const percentage = parseFloat(closedDocuments / totalDocuments) * 100;

        return {
          id: investment.id,
          name: investment.name,
          status: getStatus(percentage),
          type: globals.investmentTypes[investment.type],
          completion: getCompletion(closedDocuments, totalDocuments, percentage),
          documents: investment.documents?.join(', '),
        };
      }),
    };
  },
};
