import api from '@/utils/api';

const storeCurrent = (commit, investment) => {
  if (!investment?.id) {
    return;
  }

  commit('setCurrent', investment);
};

export default {
  fetchById: async ({ commit }, id) => {
    const investment = await api.investments.getById(id);

    storeCurrent(commit, investment);
  },
  update: async ({ commit, dispatch }, { investment, companyId }) => {
    const {
      id,
      name,
      type,
    } = investment;

    const updated = await api.investments.updateById(id, { name, type });

    storeCurrent(commit, updated);

    if (updated?.id) {
      dispatch('feedback/createItem', { title: 'Investment updated correctly' }, { root: true });

      dispatch('companies/fetchById', companyId, { root: true });
    }
  },
  create: async ({ commit, dispatch }, investment) => {
    const created = await api.investments.create(investment);

    storeCurrent(commit, created);

    if (created?.id) {
      dispatch('feedback/createItem', { title: `Investment "${created.name}" created correctly` }, { root: true });

      dispatch('companies/fetchById', investment.company, { root: true });
    }

    // Returns the ID of the created investment so the user can be redirected to the correct detail
    return created?.id;
  },
};
