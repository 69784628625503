import {
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';

import firebaseInstance from './firebase';

/**
 * Function to sign in with Google through `firebase`
 */
export default async function signIn() {
  await signInWithPopup(firebaseInstance, new GoogleAuthProvider());
  window.location.href = '/';
}
