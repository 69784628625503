<template>
  <div class="application">
    <header class="application__header">
      <Navigation class="application__navigation" />
    </header>
    <main class="application__view" :class="{ 'application__view--extended': isExtended }">
      <Breadcrumbs />
      <router-view />
    </main>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Breadcrumbs from '@/layout/Breadcrumbs.vue';
import Navigation from '@/layout/Navigation.vue';

export default defineComponent({
  components: {
    Breadcrumbs,
    Navigation,
  },
  computed: {
    isExtended() {
      return this.$route.meta?.extendedViewSize;
    },
  },
});
</script>

<style lang="scss" scoped>
.application {
  --color-background: var(--color-secondary);
  --color-text: var(--color-generic-white);
  --width-content: var(--width-container);
  --width-extended-content: var(--width-container-lg);

  &__header {
    background-color: var(--color-background);
    color: var(--color-text);
  }

  &__navigation {
    @extend %py-2;
  }

  &__view {
    @extend %py-4;
  }

  &__navigation,
  &__view {
    @extend %mx-auto;
    @extend %px-2;

    max-width: var(--width-content);

    &--extended {
      max-width: var(--width-extended-content);
    }
  }
}
</style>
