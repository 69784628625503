import globals from '@/globals';

export default {
  setList: (state, subjects) => {
    state.list = subjects.map((subject, index) => ({
      ...subject,
      color: globals.categoryColors[index],
    }));
  },
};
