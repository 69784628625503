import config from '../config';

/**
 * Storage "class"-like factory, wraps browser storage-based APIs with custom logic, such as all
 * stored keys having configured prefix, or parsing objects/JSON on set/get
 */
export default function Storage(storageProvider) {
  const { keyPrefix } = config;

  /**
   * Sets a key/value pair in the storage provider, if the value is a valid object it converts it to
   * JSON
   */
  this.set = (key, value) => {
    let parsedValue = value;

    if (/(function|symbol)/.test(typeof value)) {
      throw new Error(`A ${typeof value} should not be stored on the browser storage`);
    }

    if (value && typeof value === 'object') {
      parsedValue = JSON.stringify(value);
    }

    storageProvider.setItem(keyPrefix + key, parsedValue);
  };

  /**
   * Gets a value from the storage provider based on a key, if the value is valid JSON it gets
   * parsed before returned
   */
  this.get = (key) => {
    const value = storageProvider.getItem(keyPrefix + key);

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  };

  /**
   * Removes a value from the storage provider based on a key
   */
  this.remove = (key) => storageProvider.removeItem(keyPrefix + key);

  /**
   * Clears the storage provider
   */
  this.clear = () => storageProvider.clear();
}
