export default {
  setList: (state, documents) => {
    state.list = documents.map(({
      id,
      company,
      investment,
      type,
      assignee,
    }) => ({
      id,
      companyId: company.id,
      investmentId: investment.id,
      company: company.name,
      funds: company.funds?.map((fund) => fund.name).join(', '),
      jurisdiction: company.jurisdiction,
      investment: investment.name,
      document: type,
      assignee: assignee.name,
    }));
  },
  setOptions: (state, documents) => {
    // Finds all the unique funds on the list of documents and formats them for the options of the
    // `FormControl` component
    state.options.fund = documents.reduce((options, { company }) => {
      const funds = company.funds.filter((fund) => (
        options.every((option) => option.value !== fund.name)
      ));

      return [
        ...options,
        ...funds.map(({ name }) => ({
          label: name,
          value: name,
        })),
      ];
    }, []);

    // Finds all the unique companies on the list of documents and formats them for the options of
    // the `FormControl` component
    state.options.company = documents.reduce((options, { company }) => {
      if (options.some((option) => option.value === company.name) || !company) {
        return options;
      }

      return [
        ...options,
        {
          label: company.name,
          value: company.name,
        },
      ];
    }, []);

    // Finds all the unique jurisdictions on the list of documents and formats them for the options
    // of the `FormControl` component
    state.options.jurisdiction = documents.reduce((options, { company }) => {
      if (options.some((option) => option.value === company.jurisdiction)) {
        return options;
      }

      return [
        ...options,
        {
          label: company.jurisdiction,
          value: company.jurisdiction,
        },
      ];
    }, []);

    // Finds all the unique document types on the list of documents and formats them for the options
    // of the `FormControl` component
    state.options.documentType = documents.reduce((options, { type }) => {
      if (options.some((option) => option.value === type)) {
        return options;
      }

      return [
        ...options,
        {
          label: type,
          value: type,
        },
      ];
    }, []);

    // Finds all the unique assignees on the list of documents and formats them for the options of
    // the `FormControl` component
    state.options.assignee = documents.reduce((options, { assignee }) => {
      if (options.some((option) => option.value === assignee.name) || !assignee) {
        return options;
      }

      return [
        ...options,
        {
          label: assignee.name,
          value: assignee.name,
        },
      ];
    }, []);
  },
  setFilters: (state, filters) => {
    state.filters = { ...filters };
  },
};
