import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCScQxhTDDUvHlWp1e_9cjJChvJY1N64zM',
  authDomain: 'govin-internal-eu.firebaseapp.com',
  projectId: 'govin-internal-eu',
  measurementId: 'G-3Y1GZ1QTFJ',
};

// Firebase instance, can be used accross the application to generate module instances (i.e. `auth`)
export default initializeApp(firebaseConfig);
