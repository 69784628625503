import { findRoute } from '@/utils/router';

import Layout from '@/layout/Layout.vue';
import config from './config';

// List of all `routes.js` files found in the `views` folder and subfolders
const files = require.context('@/views/', true, /routes\.js$/);

const routes = files.keys().reduce(
  (routeGroups, fileName) => {
    // All `routes` that are more than 2 levels deep are ignored as they are sub-routes
    if (fileName.match(/\//g).length > 2) {
      return routeGroups;
    }

    const moduleRoutes = files(fileName).default;

    const authorized = moduleRoutes.filter((route) => route.meta?.isAuthRequired !== false);
    const unAuthorized = moduleRoutes.filter((route) => route.meta?.isAuthRequired === false);

    const homepageRoute = config.homepage && findRoute(moduleRoutes, config.homepage);

    if (homepageRoute) {
      homepageRoute.alias = '/';
    }

    return {
      authorized: [
        ...routeGroups.authorized,
        ...authorized,
      ],
      unAuthorized: [
        ...routeGroups.unAuthorized,
        ...unAuthorized,
      ],
    };
  },
  {
    authorized: [],
    unAuthorized: [],
  },
);

export default [
  {
    // Any not defined route will redirect to the homepage
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    // This is just a wrapper for authorized routes, so all views are rendered with the same UI
    path: '/',
    component: Layout,
    children: routes.authorized,
    meta: {
      isAuthRequired: true,
    },
  },
  ...routes.unAuthorized,
];

export const { authorized } = routes;
