import { createRouter, createWebHistory } from 'vue-router';

import { firebaseInstance } from '@/utils/auth';
import storage from '@/utils/storage';

import config from './config';

import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Indicates if either the route or any parent route requires authorization
  const isAuthRequired = to.matched.some((route) => route.meta?.isAuthRequired);
  // Indicates if the user is authenticated on Firebase
  const isSignedIn = !!firebaseInstance.currentUser;

  if (isAuthRequired && !isSignedIn) {
    if (to.path !== '/') {
      window.alert('Sign in required!');

      // As the user is not authenticated, the path they tried to access is stored so they can be
      // redirected after signing in
      storage.session.set(config.redirectPathKey, to.fullPath);
    }

    // In case the route requires auth and the user is not yet signed in, they are redirected to the
    // sign in view
    next('/sign-in');
  } else if (to.matched.some((route) => route.name === 'Sign in') && isSignedIn) {
    // Tries to find out if the user tried to access any specific path before signing in
    const redirectPath = storage.session.get(config.redirectPathKey);

    storage.session.remove(config.redirectPathKey);

    // In case the user is signed in and navigates to the sign in view, redirect to either the path
    // that they tried to access before signing in or to the homepage
    next(redirectPath || '/');
  } else {
    // If none of the cases above apply, the user can access the view normally
    next();
  }
});

export default router;
