import store from '@/store';

import { firebaseInstance } from '@/utils/auth';

const formatComment = (comment) => ({
  ...comment,
  text: comment.text.replace(/\n/g, '<br>'),
  createdBy: comment.createdBy?.name === firebaseInstance?.currentUser?.displayName
    ? 'You'
    : comment.createdBy?.name,
});

const formatAnnotation = (annotation) => {
  const {
    financialThreshold,
    createdBy,
    updatedBy,
  } = annotation;

  const decisionMakingBodies = store.getters['votingStructures/decisionMakingBodies'];

  const subject = (annotation.subject || []).reverse();

  return {
    ...annotation,
    // API returns the subjects in the opposite order we need to display them
    subject,
    suggestion: annotation.status === 'AI_GENERATED',
    matchPercentage: annotation?.properties?.matchPercentage,
    createdBy: createdBy?.name === firebaseInstance?.currentUser?.displayName
      ? 'You'
      : createdBy?.name,
    updatedBy: updatedBy?.name === firebaseInstance?.currentUser?.displayName
      ? 'You'
      : updatedBy?.name,
    financialThreshold: {
      amount: financialThreshold?.amount && parseFloat(financialThreshold.amount),
      operator: financialThreshold?.operator,
    },
    comments: (annotation.thread?.comments || []).map(formatComment),
    highlights: annotation.highlights.map((highlight) => ({
      ...highlight,
      annotation: {
        id: annotation.id,
        subject,
        suggestion: annotation.status === 'AI_GENERATED',
      },
    })),
    votingThresholds: annotation.votingThresholds.map((annotationVotingThreshold) => {
      const decisionMakingBody = decisionMakingBodies.find(({ votingThresholds }) => (
        votingThresholds.find(({ id }) => annotationVotingThreshold.votingThresholdId === id)
      ));

      const votingThreshold = decisionMakingBody.votingThresholds.find(({ id }) => (
        annotationVotingThreshold.votingThresholdId === id
      ));

      const conditionsOverride = annotationVotingThreshold.conditions;
      const percentageOverride = conditionsOverride.find(({ type }) => type === 'PERCENTAGE') || {};
      const quorumOverride = conditionsOverride.find(({ type }) => type.includes('QUORUM')) || {};

      const conditionsOriginal = votingThreshold.conditions;
      const percentageOriginal = conditionsOriginal.find(({ type }) => type === 'PERCENTAGE') || {};
      const quorumOriginal = conditionsOriginal.find(({ type }) => type.includes('QUORUM')) || {};

      return {
        id: annotationVotingThreshold.id,
        decisionMakingBody,
        votingThreshold,
        override: {
          percentage: {
            conditionId: percentageOriginal.id,
            type: 'PERCENTAGE',
            value: undefined,
            ...percentageOverride,
          },
          quorum: {
            conditionId: quorumOriginal.id,
            type: quorumOriginal.type || null,
            value: undefined,
            ...quorumOverride,
          },
        },
        original: {
          percentage: { ...percentageOriginal },
          quorum: { ...quorumOriginal },
        },
      };
    }),
  };
};

export default {
  setSerialized: (state, serializedHighlights) => {
    state.serialized = serializedHighlights;
  },
  setAnnotations: (state, annotations) => {
    state.annotations = annotations.map(formatAnnotation);
  },
  setStakeholders: (state, stakeholders) => {
    state.stakeholders = stakeholders;
  },
  extendStakeholders: (state, stakeholder) => {
    state.stakeholders = [...state.stakeholders, stakeholder];
  },
  replaceStakeholder: (state, stakeholder) => {
    state.stakeholders = state.stakeholders.map(
      (item) => (item.id === stakeholder.id ? { ...item, ...stakeholder } : item),
    );
  },
  deleteStakeholder: (state, stakeholder) => {
    state.stakeholders = state.stakeholders.filter(({ id }) => stakeholder.id !== id);
  },
  extendAnnotations: (state, annotation) => {
    state.annotations = [...state.annotations, formatAnnotation(annotation)];
  },
  replaceAnnotation: (state, annotation) => {
    state.annotations = state.annotations.map(
      (item) => (item.id === annotation.id ? { ...item, ...formatAnnotation(annotation) } : item),
    );
  },
  deleteAnnotation: (state, annotation) => {
    state.annotations = state.annotations.filter(({ id }) => annotation.id !== id);
  },
  removeAnnotationVotingThreshold: (state, { annotation, annotationVotingThreshold }) => {
    const parent = state.annotations.find(({ id }) => annotation.id === id);

    parent.votingThresholds = parent.votingThresholds.filter(
      ({ id }) => annotationVotingThreshold.id !== id,
    );
  },
  extendAnnotationComments: (state, { annotation, comment }) => {
    state.annotations = state.annotations.map((item) => (
      item.id === annotation.id
        ? { ...item, comments: [item.comments, formatComment(comment)] }
        : item
    ));
  },
};
