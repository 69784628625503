/**
 * Gets the page title which can either be the `title` property of a view, or the name of the route
 *
 * @param {object} scope - `Vue` component scope (`this`)
 *
 * @returns {string} - Page title (can be `''`)
 */
export default (scope) => {
  const { title: pageTitle } = scope.$options;

  if (pageTitle) {
    return typeof pageTitle === 'function' ? pageTitle.call(scope) : pageTitle;
  }

  const route = scope.$route.matched[scope.$route.matched.length - 1];

  if (route && route.name) {
    return route.name;
  }

  return '';
};
