import storage from '../storage';

import firebaseInstance from './firebase';

/**
 * Function to sign out from `firebase`, clears the browser storages and redirects to the homepage,
 * so then the application is reloaded with a clean state
 */
export default async function signOut() {
  await firebaseInstance.signOut();

  storage.local.clear();
  storage.session.clear();

  window.location.href = '/sign-in';
}
