export default {
  total: (state) => state.list.length,
  list: ({ list, filters }) => list.filter(({
    funds,
    company,
    jurisdiction,
    type,
    assignee,
  }) => (
    (
      !filters.fund
      || (filters.fund === 'none' ? !funds : funds.includes(filters.fund))
    )
    && (!filters.company || company === filters.company)
    && (!filters.jurisdiction || jurisdiction === filters.jurisdiction)
    && (!filters.documentType || type === filters.documentType)
    && (!filters.assignee || assignee === filters.assignee)
  )),
  options: (state) => state.options,
};
