import getType from './private/getType';

/**
 * Returns the completion of a fund, based on the document status and the calculated percentage
 *
 * @param {number} closed - Amount of fully onboarded documents
 * @param {number} total - Total amount of documents
 * @param {number} percentage - Percentage of onboarding completion
 *
 * @returns {object} - Object with the completion label, the type and the title (both used on the
 * `Badge` component)
 */
export default (closed, total, percentage) => ({
  label: `${closed} / ${total}`,
  type: getType(percentage),
  title: `${percentage.toFixed(1)}%`.replace('.0', ''),
});
