export default {
  latest: [],
  list: [],
  options: {
    createdBy: [],
    company: [],
    jurisdiction: [],
    documentType: [],
  },
};
