export default [
  {
    path: '/companies',
    name: 'Companies',
    component: () => import(/* webpackChunkName: "companies" */ './List.vue'),
  },
  {
    path: '/companies/create',
    name: 'Create company',
    component: () => import(/* webpackChunkName: "create-company" */ './Form.vue'),
  },
  {
    path: '/company/:companyId',
    name: 'Company',
    component: () => import(/* webpackChunkName: "company" */ './Detail.vue'),
    props: true,
  },
  {
    path: '/company/:companyId/investment/:investmentId',
    name: 'Investment',
    component: () => import(/* webpackChunkName: "investment" */ './investment/Detail.vue'),
    props: true,
  },
  {
    path: '/company/:companyId/investments/create',
    name: 'Create investment',
    component: () => import(/* webpackChunkName: "create-investment" */ './investment/Form.vue'),
    props: true,
  },
  {
    path: '/company/:companyId/investment/:investmentId/document/:documentId',
    name: 'Document',
    component: () => import(/* webpackChunkName: "document" */ './document/Detail.vue'),
    props: true,
    meta: {
      extendedViewSize: true,
    },
  },
  {
    path: '/company/:companyId/investment/:investmentId/documents/create',
    name: 'Create document',
    component: () => import(/* webpackChunkName: "create-document" */ './document/Form.vue'),
    props: true,
  },
];
