import api from '@/utils/api';

const storeList = (commit, users) => {
  if (!users) {
    return;
  }

  commit('setList', users);
};

export default {
  fetchAll: async ({ commit, getters }) => {
    // If the users have not been loaded, they are load synchronously, blocking rendering
    if (!getters.list.length) {
      const users = await api.users.default();

      storeList(commit, users);

      return;
    }

    api.users.default().then((users) => storeList(commit, users));
  },
};
